import React from "react";
import Layout from "./src/components/Layout";
import "./src/styles/global.css";
import { StateContext } from "./src/context/StateContext";

export const wrapRootElement = ({ element }) => (
  <StateContext>
    <Layout>{element}</Layout>
  </StateContext>
);
