import React from "react";
import { SEO } from "./SEO";
import Footer from "../components/Footer";
import { Navbar } from "./Navbar";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div
      className="layout"
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <SEO />
      <header style={{ flexShrink: 0 }}>
        <Navbar />
      </header>
      <main className="main-container" style={{ flex: 1 }}>
        {children}
      </main>
      <Footer style={{ flexShrink: 0 }} />
    </div>
  );
};

export default Layout;

{
  /* <Navbar /> */
}
{
  /* <Link to="#">B-zyne</Link>
          <Link to="#"> {t("Home")}</Link>
          <Link to="#"> {t("About")}</Link>
          <Link to="#"> {t("Contact")}</Link>
          {/* <div id="marker" ref={markerRef}></div> */
}
{
  /* <ul>
            {languages.map((lng) => (
              <li key={lng}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    changeLanguage(lng);
                  }}
                >
                  {lng}
                </a>
              </li>
            ))}
          </ul> */
}

// const markerRef = useRef<HTMLDivElement | null>(null);
// const navItemsRef = useRef<NodeListOf<HTMLAnchorElement> | null>(null);

// useEffect(() => {
//   navItemsRef.current = document.querySelectorAll("nav a");

//   navItemsRef.current?.forEach((link) => {
//     link.addEventListener("click", (e) => {
//       moveMarker(e.target as HTMLAnchorElement);
//     });
//   });
// }, []);

// const moveMarker = (target: HTMLAnchorElement) => {
//   if (markerRef.current) {
//     markerRef.current.style.left = `${target.offsetLeft}px`;
//     markerRef.current.style.width = `${target.offsetWidth}px`;
//   }
// };
// const { languages, originalPath, changeLanguage, i18n } = useI18next();
{
  /* <Link to="/">en</Link>
          <Link to="/fr/">fr</Link>
          <Link to="/ar/">ar</Link> */
}
{
  /* <ul className="languages">
            {languages.map((lng) => (
              <li key={lng}>
                <Link
                  to={originalPath}
                  onClick={(e) => {
                    e.preventDefault();
                    changeLanguage(lng);
                    navigate(`/${lng}${originalPath}`);
                  }}
                >
                  {lng}
                </Link>
              </li>
            ))}
          </ul> */
}
{
  /* <ul className="languages">
  {languages.map((lng) => (
    <li key={lng}>
      <Link to={originalPath} language={"en"}>
        {"en"}
      </Link>
      <Link to={originalPath} language={"fr"}>
        {"fr"}
      </Link>
      <Link to={originalPath} language={"ar"}>
        {"ar"}
      </Link>
    </li>
  ))}
</ul>; */
}
{
  /* <Link to="/">en</Link>
          <Link to="/fr/">fr</Link>
          <Link to="/ar/">ar</Link> */
}
{
  /* <ul className="languages">
              {languages.map((lng) => (
                <li key={lng}>
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      changeLanguage("ar");
                    }}
                  >
                    {"ar"}
                  </a>
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      changeLanguage("fr");
                    }}
                  >
                    {"fr"}
                  </a>
                </li>
              ))}
            </ul> */
}
{
  /* <ul className="languages">
              {languages.map((lng) => (
                <li key={lng}>
                  <Link
                    to={originalPath}
                    language={lng}
                    style={{
                      textDecoration:
                        i18n.resolvedLanguage === lng ? "underline" : "none",
                    }}
                  >
                    {lng}
                  </Link>
                </li>
              ))}
            </ul> */
}
{
  /* <ul className="languages">
              {languages.map((lng) => {
                return (
                  <li key={lng} className="LanguageListItem">
                    <Link
                      to={originalPath}
                      language={"ar"}
                      className="LanguageListItem"
                    >
                      {"ar"}
                    </Link>
                    <Link
                      to={originalPath}
                      language={"fr"}
                      className="LanguageListItem"
                    >
                      {"fr"}
                    </Link>
                  </li>
                );
              })}
            </ul> */
}
