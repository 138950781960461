import React from "react";
import { Link } from "gatsby";
import { FaBagShopping } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { motion } from "framer-motion";
import Cart from "./Cart";
import { useStateContext } from "../context/StateContext.js";

export const Navbar = () => {
  const { showCart, setShowCart, totalQuantities } = useStateContext();

  const phoneNumber = "0617595668";

  return (
    <nav style={{ display: "flex", justifyContent: "space-between" }}>
      <div>
        <Link
          to="/"
          style={{ fontFamily: "Protest Guerrilla", color: "black" }}
        >
          B-zyne
        </Link>
      </div>
      <div>
        <motion.a
          href={`https://wa.me/${212617595668}`}
          target="_blank"
          rel="noopener noreferrer"
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.9 }}
          style={{
            display: "flex",
            alignItems: "center",
            color: "green",
            marginRight: "40px",
          }}
        >
          <FaWhatsapp size={25} />
          {phoneNumber}
        </motion.a>
      </div>
      <div>
        <button
          type="button"
          className="cart-icon"
          onClick={() => setShowCart(true)}
        >
          <FaBagShopping />
          <span className="cart-item-qty">{totalQuantities}</span>
        </button>
        {showCart && <Cart />}
      </div>
    </nav>
  );
};
