import React from "react";
import { FaInstagram, FaXTwitter } from "react-icons/fa6";

interface FooterProps {
  style?: React.CSSProperties;
}

const Footer: React.FC<FooterProps> = ({ style }) => {
  return (
    <div className="footer-container" style={style}>
      <p>2023 B-zyne All rights reserverd</p>
      <p className="icons">
        <FaInstagram />
        <FaXTwitter />
      </p>
    </div>
  );
};

export default Footer;
