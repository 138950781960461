import React, { useRef } from "react";
import { navigate, Link } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { useStateContext } from "../context/StateContext";
import { TiDeleteOutline } from "react-icons/ti";
import {
  AiOutlineMinus,
  AiOutlinePlus,
  AiOutlineLeft,
  AiOutlineShopping,
} from "react-icons/ai";

interface CartItem {
  _id: string;
  name: string;
  price: number;
  quantity: number;
  image: {
    asset: {
      gatsbyImageData: IGatsbyImageData;
    };
  }[];
  selectedImageIndex: number;
}

const Cart = () => {
  const cartRef = useRef(null);
  const {
    totalPrice,
    totalQuantities,
    cartItems,
    setShowCart,
    onRemove,
    toggleCartItemQuanitity,
  } = useStateContext();
  const handleCODPayment = () => {
    const subtotal = cartItems.reduce(
      (totalPrice, item) => totalPrice + item.price * item.quantity,
      0
    );
    const products = cartItems.map((item) => ({
      name: item.name,
      price: item.price,
      quantity: item.quantity,
    }));
    // Get the image URLs of all products in the cart
    const productImages = cartItems.map((item) => {
      // Assuming the selectedImageIndex property exists in each item
      const selectedImageIndex = item.selectedImageIndex || 0;
      // Assuming the image array exists in each item
      const imageUrl =
        item.image &&
        item.image[selectedImageIndex]?.asset?.gatsbyImageData?.images?.fallback
          ?.src;
      return imageUrl;
    });
    // Hide the cart
    setShowCart(false);
    // Navigate to the checkout page with subtotal, productImages, and products in the state
    navigate("/form", {
      state: {
        subtotal: subtotal,
        productImages: productImages,
        products: products,
      },
    });
  };
  return (
    <div className="cart-wrapper responsive" ref={cartRef}>
      <div className="cart-wrapper">
        <div className="cart-container">
          <button
            type="button"
            className="cart-heading"
            onClick={() => setShowCart(false)}
          >
            <AiOutlineLeft />
            <span className="heading">Your Cart</span>
            <span className="cart-num-items">({totalQuantities} items)</span>
          </button>
          {cartItems.length < 1 && (
            <div className="empty-cart">
              <AiOutlineShopping size={150} className="shopping-icon" />
              <h3>The cart is empty</h3>
              <Link to="/">
                <button
                  type="button"
                  onClick={() => setShowCart(false)}
                  className="Btn"
                >
                  Continue Shopping
                </button>
              </Link>
            </div>
          )}
          <div className="product-container">
            {cartItems.length >= 1 &&
              cartItems.map((item) => (
                <div className="product" key={item._id}>
                  {item.image?.map((img, imgIndex) => {
                    const imageUrl =
                      img.asset?.gatsbyImageData?.images?.fallback?.src;
                    return imgIndex === item.selectedImageIndex ? (
                      <img
                        key={imgIndex}
                        src={imageUrl}
                        alt={item.name}
                        className="product-image"
                        style={{ maxWidth: "20%", height: "auto" }}
                      />
                    ) : null;
                  })}
                  <div className="item-desc">
                    <div className="flex top">
                      <h5>{item.name}</h5>
                      <h4>${item.price}</h4>
                    </div>
                    <div className="flex bottom">
                      <div>
                        <p
                          className="quantity-desc"
                          style={{ display: "flex" }}
                        >
                          <span
                            className="minus"
                            onClick={() =>
                              toggleCartItemQuanitity(item._id, "dec")
                            }
                          >
                            <AiOutlineMinus />
                          </span>
                          <span className="num" onClick={() => {}}>
                            {item.quantity}
                          </span>
                          <span
                            className="plus"
                            onClick={() =>
                              toggleCartItemQuanitity(item._id, "inc")
                            }
                          >
                            <AiOutlinePlus />
                          </span>
                        </p>
                      </div>
                      <button
                        type="button"
                        className="remove-item"
                        onClick={() => onRemove(item)}
                      >
                        <TiDeleteOutline />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {cartItems.length >= 1 && (
            <div className="cart-bottom">
              <div className="total">
                <h3>Subtotal:</h3>
                <h3>
                  $
                  {cartItems.reduce(
                    (totalPrice, item) =>
                      totalPrice + item.price * item.quantity,
                    0
                  )}
                </h3>
              </div>
              <div className="btn-container">
                <button
                  type="button"
                  className="Btn"
                  onClick={handleCODPayment}
                >
                  CHECKOUT
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cart;
