import React, { ReactNode } from "react";
import { useSiteMetadata } from "./hooks/use-site-metadata";

interface SEOProps {
  title?: string;
  description?: string;
  pathname?: string;
  children?: ReactNode;
  keywords?: string;
}

export const SEO = ({
  title,
  description,
  pathname,
  keywords,
  children,
}: SEOProps) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    keywords: defaultkeywords,
    image,
    siteUrl,
  } = useSiteMetadata();
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    keywords: keywords || defaultkeywords,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ``}`,
  };
  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="keywords" content="store, b-zyne, shopping" />
      {/* <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={seo.title} />
          <meta name="twitter:url" content={seo.url} />
          <meta name="twitter:description" content={seo.description} />
          <meta name="twitter:image" content={seo.image} />
          <meta name="twitter:creator" content={seo.twitterUsername} /> */}
      {/* <link
        rel="icon"
        href="/favicon-32x32.png?v=0010f822bcd9a079c40125afefb0fc8a"
        type="image/png"
      ></link> */}
      {/* <link
        rel="icon"
        href="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><text y='0.9em' font-size='90'>👤</text></svg>"
      /> */}
      {children}
    </>
  );
};
